//
// alert.scss
// Extended from Bootstrap
//

// Color variants
//
// Using Bootstrap's core alert-variant mixin to generate solid background color + yiq colorized text (and making close/links match those colors)

// @each $color, $value in $theme-colors {
//   .alert-#{$color} {
//     @include alert-variant(shift-color($value, $alert-bg-scale), shift-color($value, $alert-border-scale), color-contrast(shift-color($value, $alert-bg-scale)));

//     .alert-link, .btn-close {
//       color: color-contrast(shift-color($value, $alert-bg-scale));
//     }

//     hr {
//       background-color: darken(shift-color($value, $alert-border-scale), 5%);
//     }
//   }
// }

// alert.scss
// Extended from Bootstrap

// Color variants

// alert.scss
// Extended from Bootstrap

// Color variants

@each $color, $value in $theme-colors {
  $adjustedBg: shift-color($value, $alert-bg-scale);
  $adjustedBorder: shift-color($value, $alert-border-scale);
  $adjustedContrast: color-contrast($adjustedBg);
  
  .alert-#{$color} {
    --alert-color: #{$adjustedContrast};
    --alert-bg: #{$adjustedBg};
    --alert-border-color: #{$adjustedBorder};
    
    color: var(--alert-color);
    background-color: var(--alert-bg);
    border-color: var(--alert-border-color);
    
    .alert-link, .btn-close {
      color: var(--alert-color);
    }
    
    hr {
      background-color: darken($adjustedBorder, 5%);
    }
  }
}
