/* Workout Cell Styling Fixes */

/* Fix card appearance */
.workout-cell {
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  background-color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.workout-cell:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
}

/* Fix image ratios and appearance */
.workout-cell-image-wrapper {
  width: 100%;
  aspect-ratio: 16/9;
  position: relative;
  overflow: hidden;
  background-color: #f1f4f8;
  flex-shrink: 0;
}

.workout-cell-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  transition: transform 0.3s ease;
}

.workout-cell:hover .workout-cell-image {
  transform: scale(1.05);
}

/* Overlay gradient */
.workout-cell-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.4) 100%);
  z-index: 1;
}

/* Fix content padding */
.workout-cell-content {
  padding: 18px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

/* Timestamp and type badges */
.workout-cell-meta {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  flex-wrap: wrap;
}

.workout-cell-timestamp {
  font-size: 13px;
  color: #6b7280;
  font-weight: 500;
  display: flex;
  align-items: center;
  margin-right: 12px;
}

.workout-cell-timestamp svg {
  margin-right: 4px;
}

.workout-cell-badge {
  display: inline-flex;
  align-items: center;
  padding: 3px 8px;
  border-radius: 4px;
  background-color: rgba(59, 130, 246, 0.1);
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  color: #3b82f6;
}

.workout-cell-badge.workout {
  background-color: #e9f1ff;
  color: #3b82f6;
}

.workout-cell-badge svg {
  margin-right: 4px;
  font-size: 14px;
}

/* Fix title */
.workout-cell-title {
  font-size: 18px;
  font-weight: 700;
  color: #1f2937;
  margin-bottom: 8px;
  line-height: 1.3;
}

/* Description styling */
.workout-cell-description {
  font-size: 14px;
  color: #6b7280;
  line-height: 1.5;
  margin-bottom: 15px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  flex-grow: 1;
}

/* Footer area */
.workout-cell-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: auto;
  padding-top: 12px;
  border-top: 1px solid #f3f4f6;
}

.workout-cell-author {
  display: flex;
  align-items: center;
  font-size: 13px;
  color: #6b7280;
}

.workout-cell-view-link {
  font-size: 14px;
  font-weight: 600;
  color: #3b82f6;
  display: flex;
  align-items: center;
  transition: color 0.2s;
}

.workout-cell-view-link:hover {
  color: #2563eb;
}

.workout-cell-view-link svg {
  margin-left: 4px;
  font-size: 16px;
}

/* Grid Layout */
.workout-cells-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 24px;
}

@media (max-width: 768px) {
  .workout-cells-grid {
    grid-template-columns: 1fr;
    gap: 16px;
  }
} 